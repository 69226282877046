<template>
  <div class="pt-24">
    <div v-if="loading">
      <div style="border-top-color:transparent"
           class="w-6 h-6 border-4 border-blue-400 border-double rounded-full animate-spin"></div>
    </div>
    <div class="rounded-md bg-green-50 p-4" v-else>
      <div class="flex">
        <div class="flex-shrink-0">
          <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div class="ml-3">
          <h3 class="text-sm font-medium text-green-800">
            Order completed
          </h3>
          <div class="mt-2 text-sm text-green-700">
            <p>
              Payment success
            </p>
          </div>
          <div class="mt-4">
            <p>You bought courses:</p>
              <router-link v-for="course in courses" :to="`/course/${course.pk}`" type="button" class="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                {{course.product.name}}
              </router-link>


            <div class="-mx-2 -my-1.5 flex mt-10">
              <router-link to="/" type="button" class="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                Go to homepage
              </router-link>
              <router-link to="/initial" type="button" class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                Go to your initial courses
              </router-link>
              <router-link to="/continuation" type="button" class="ml-3 bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600">
                Go to your continuation courses
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid'

export default {
  components: {
    CheckCircleIcon,
  },
  data() {
    return {
      loading: true,
      courses: []
    }
  },
  async mounted() {
    let res = await this.axios.post('user/payments-confirm/', {
      payment_intent: this.$route.query['payment_intent']
    })
    this.loading = !res.data.status == 'ok'
    this.courses = res.data.courses

    await this.$store.dispatch('courses/fetchCourses')
    await this.$store.dispatch('auth/fetchUser')
  }
}
</script>